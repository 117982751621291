@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  position: relative;
  z-index: 9999;
  background-color: #010206;
  min-height: 100vh;
}

.line1 {
  position: absolute;
  width: 0.3px;
  height: 100%;
  background-color: #222222;
  top: 0;
  left: 10%;
}

.line2 {
  position: absolute;
  width: 0.3px;
  height: 100%;
  background-color: #222222;
  top: 0;
  left: 30%;
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.line3 {
  position: absolute;
  width: 0.3px;
  height: 100%;
  background-color: #222222;
  top: 0;
  left: 50%;
}

.line4 {
  position: absolute;
  width: 0.3px;
  height: 100%;
  background-color: #222222;
  top: 0;
  left: 70%;
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.line5 {
  position: absolute;
  width: 0.3px;
  height: 100%;
  background-color: #222222;
  top: 0;
  left: 90%;
}

.horizontal__line {
  position: absolute;
  width: 100%;
  height: 0.3px;
  background-color: #686976;
  top: 12%;

  @media screen and (max-width: 800px) {
    top: 8%;
  }
  @media screen and (max-width: 600px) {
    top: 6%;
  }
  @media screen and (max-width: 400px) {
    top: 6%;
  }
}
