.sweepwidget {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -42%);
  width: 1300px;
  height: 790px;
  border: none;
  z-index: 999;
  @media screen and (max-width: 1440px) {
    width: 1000px;
    height: 665px;
    transform: translate(-50%, -48%);
  }
  @media screen and (max-width: 1024px) {
    width: 800px;
  }
  @media screen and (max-width: 868px) {
    width: 750px;
    height: 765px;
    transform: translate(-50%, -43%);
  }
  @media screen and (max-width: 800px) {
    width: 550px;
    height: 1210px;
    transform: translate(-50%, -45%);
  }
  @media screen and (max-width: 600px) {
    width: 400px;
    height: 1300px;
    transform: translate(-50%, -45%);
  }
  @media screen and (max-width: 400px) {
    width: 350px;
  }
}

.work_for_bih {
  position: relative;
  background: #152861;
  width: 1050px;
  height: 310px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media screen and (max-width: 1440px) {
    width: 800px;
    height: 255px;
  }
  @media screen and (max-width: 1024px) {
    width: 600px;
    height: 255px;
  }
  @media screen and (max-width: 868px) {
    width: 100%;
    height: 255px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    height: 255px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 330px;
  }
}

.campaign_left_div {
  position: relative;
  text-align: center;
  padding: 10px;
  border: 1px solid transparent;
  border-image: linear-gradient(40deg,#000000 10%, transparent -40%, #ffffff 40%, transparent);
  border-image-slice: 1;
  width: 600px;
  height: 420px;
  background: #152861;
  @media screen and (max-width: 1440px) {
    width: 470px;
    height: 350px;
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) {
    width: 370px;
    height: 350px;
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    width: 550px;
    height: 350px;
    margin: 30px auto;
  }
}

.campaign_right_div {
  position: relative;
  text-align: center;
  padding: 10px;
  border: 1px solid transparent;
  border-image: linear-gradient(40deg,#000000 10%, transparent -40%, #ffffff 40%, transparent);
  border-image-slice: 1;
  width: 600px;
  height: 420px;
  background: #152861;
  @media screen and (max-width: 1440px) {
    width: 470px;
    height: 350px;
    margin-right: 0;
  }
  @media screen and (max-width: 1024px) {
    width: 370px;
    height: 350px;
    margin-right: 0;
  }
  @media screen and (max-width: 800px) {
    width: 550px;
    height: 350px;
    margin: 30px auto;
  }
}
